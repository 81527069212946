/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useState, useRef } from 'react';
import { Checkbox, Paper, Grid, NumberInput, TextInput, Center, Box, Flex, ActionIcon } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { QuestionStateUpdate } from './QuestionsState';
import { hasFixedAnswers } from '../../../../js/modules/Build/Assessment/QuestionType';
import { isPresetRating, QuestionType } from '../../../../js/generated/enums/QuestionType';
import { MAX_SCORE_PRECISION } from './util';
import { useDispatch } from 'react-redux';
import { associatedAnswersChanged } from './UnpublishedQuestionLogic/unpublishedLogicSlice';
import { IconGripVertical } from '@tabler/icons-react';

const defaultInputStyles = {
  input: { fontSize: '1rem' }
}

/**
 * @param {Answer} answer
 * @param {QuestionType} questionType
 * @param {boolean} hasDependentLogic
 * @param dragging
 * @param dispatch
 */
const AnswerEditor = memo(function AnswerEditor ({ answer, position, hasDependentLogic, questionType, dragging, dispatch, handleProps }) {
  const [content, setContent] = useState(answer.content)
  const contentDirty = useRef(null)
  const reduxDispatch = useDispatch()
  const isPresetRatingQuestion = isPresetRating(questionType)
  const editContentRef = useClickOutside(() => {
    window.clearTimeout(contentDirty.current)
    if (answer.content !== content) {
      dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }
  })

  const updateAnswerText = useCallback(() => {
    window.clearTimeout(contentDirty.current)
    dispatch({ type: QuestionStateUpdate.UpdateAnswer, answerId: answer.id, questionId: answer.questionId, newAttributes: { content } })
    if (hasDependentLogic) {
      reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
    }
  }, [answer.id, answer.questionId, content, dispatch, reduxDispatch, hasDependentLogic])

  const bufferAnswerText = useCallback((event) => {
    window.clearTimeout(contentDirty.current)
    const initialContent = event.currentTarget.value
    setContent(initialContent)
    contentDirty.current = window.setTimeout(() => {
      dispatch({
        type: QuestionStateUpdate.UpdateAnswer,
        answerId: answer.id,
        questionId: answer.questionId,
        newAttributes: { content: initialContent }
      })
      if (hasDependentLogic) {
        reduxDispatch(associatedAnswersChanged({ questionId: answer.questionId }))
      }
    }, 1000);
  }, [answer.id, answer.questionId, setContent, dispatch, reduxDispatch, hasDependentLogic])

  const isInterview = questionType === QuestionType.Interview
  const isFillInTheBlank = questionType === QuestionType.FillInTheBlank
  const isInterviewOrFillInBlank = isInterview || isFillInTheBlank
  const isChooseAll = questionType === QuestionType.ChooseAllThatApply
  // TODO use of LeftSection in text input is prevented by styling from elsewhere in site, likely foundation.

  const newPlaceholder = 'New Answer'
  const answerIsNew = content === newPlaceholder
  return (
    <Paper
      bg={dragging ? 'blue.1' : 'gray.2'}
    >
      <Grid justify='flex-start' align='center'>
        {isFillInTheBlank
          ? null
          : (
          <Grid.Col span={2}>
            <Flex align='flex-end'>
              {!!handleProps && <ActionIcon {...handleProps}><IconGripVertical /></ActionIcon>}
              {position}.
            </Flex>
          </Grid.Col>
            )}
        <Grid.Col span={(isInterview) ? 10 : 'auto'}>
          <Box py={4}>
            <TextInput
              styles={defaultInputStyles}
              placeholder={answerIsNew ? 'New Answer' : 'Blank Answer'}
              value={answerIsNew ? '' : content}
              onChange={bufferAnswerText}
              onBlur={updateAnswerText}
              disabled={hasFixedAnswers(questionType) && !isInterviewOrFillInBlank}
              ref={editContentRef}
            />
          </Box>
        </Grid.Col>
        {isInterview
          ? null
          : (
          <Grid.Col span={2}>
            <NumberInput
              fz='1rem'
              styles={defaultInputStyles}
              value={answer.score}
              decimalScale={MAX_SCORE_PRECISION}
              placeholder='points'
              step={1}
              onChange={(value) => dispatch({ type: QuestionStateUpdate.SetAnswerScore, answerId: answer.id, questionId: answer.questionId, newScore: (value || 0) }) }
            />
          </Grid.Col>
            )}
        {(isInterview || isChooseAll || isPresetRatingQuestion)
          ? null
          : (
          <Grid.Col span={2}>
            <Center>
              <Checkbox
                checked={!!answer.correct}
                disabled={isFillInTheBlank}
                onChange={(event) => dispatch({ type: QuestionStateUpdate.SetCorrectAnswer, answerId: answer.id, questionId: answer.questionId, newScore: (event.currentTarget.checked ? 1 : 0) })}
              />
            </Center>
          </Grid.Col>
            )}
      </Grid>
    </Paper>
  )
})

export default AnswerEditor
