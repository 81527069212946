/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useMemo } from 'react';
import { Button, Checkbox, Paper, Grid, Space, Text, Group, Flex, Box, Center, UnstyledButton, ScrollArea, ActionIcon } from '@mantine/core';
import { useClickOutside, useHover } from '@mantine/hooks';
import { QuestionStateUpdate } from './QuestionsState';
import AnswerEditor from './AnswerEditor';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import {
  hasAnswerTemplates,
  hasFixedAnswers
} from '../../../../js/modules/Build/Assessment/QuestionType';
import { isPresetRating, QuestionType } from '../../../../js/generated/enums/QuestionType';
import { answerScoreToString } from './util';
import { useDispatch, useSelector } from 'react-redux';
import {
  associatedAnswersChanged,
  hasAnyLogicDependentOnResponse
} from './UnpublishedQuestionLogic/unpublishedLogicSlice';
import { AnswerTemplates } from './AnswerTemplates';
import { IconGripVertical } from '@tabler/icons-react';

/**
 * @param {Question} question
 * @param {?int} activeAnswerId
 * @param answers
 * @param {QuestionType} questionType
 * @param dispatch
 */
const AnswersList = memo(function AnswersList ({ questionId, answers, questionType, activeAnswerId, dispatch }) {
  const reduxDispatch = useDispatch()
  const hasDependentLogic = useSelector((state) => hasAnyLogicDependentOnResponse(state, questionId))
  const isInterviewOrFillInBlank = useMemo(() => (questionType === QuestionType.FillInTheBlank) || (questionType === QuestionType.Interview), [questionType])
  const isChooseAll = questionType === QuestionType.ChooseAllThatApply
  const isInterview = questionType === QuestionType.Interview
  const isFillInTheBlank = questionType === QuestionType.FillInTheBlank
  const isPresetRatingQuestion = isPresetRating(questionType)
  const clickOutsideRef = useClickOutside(() => dispatch({ type: QuestionStateUpdate.FocusAnswer, answerId: null }))

  const canAddAnswers = useMemo(() => {
    return !hasFixedAnswers(questionType) || (questionType === QuestionType.FillInTheBlank)
  }, [questionType])
  const canRemoveAnswers = useMemo(() => {
    return !hasFixedAnswers(questionType) || ((questionType === QuestionType.FillInTheBlank) && (answers.size > 1))
  }, [questionType, answers.size])

  const answersList = useMemo(() => {
    return Array.from(answers.values())
  }, [answers])

  const getInterviewOrFillInBlankAnswerPosition = (position) => {
    if (questionType === QuestionType.FillInTheBlank) {
      return 1
    }
    return 5 - position
  }

  const dragDisabled = (answers.size <= 1) || isInterview || isFillInTheBlank
  return (
    <Box ref={clickOutsideRef} mb='xs'>
      <Paper
        bg='gray.3'
        mt='sm'
        my={4}
      >
        <Grid justify='flex-start' align='center'>
          {isFillInTheBlank
            ? null
            : (
              <Grid.Col span={2}>
                <Text fw={700} size='md'>Order</Text>
              </Grid.Col>
              )
          }
          <Grid.Col span='auto'>
            <Text fw={700} size='md'>Answers</Text>
          </Grid.Col>
          {isInterview
            ? null
            : (
              <Grid.Col span={2}>
                <Text ta='center' fw={700} size='md'>Points</Text>
              </Grid.Col>
              )
          }
          {(isInterview || isChooseAll || isPresetRatingQuestion)
            ? null
            : (
              <Grid.Col span={2}>
                <Text ta='center' fw={700} size='md'>Correct</Text>
              </Grid.Col>
              )
          }
        </Grid>
      </Paper>
      <ScrollArea.Autosize mah='20rem' scrollbars='y'>
    <Droppable droppableId={questionId.toString()} type='ANSWER' isDropDisabled={dragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
            <>
              <div>
                {answersList.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={dragDisabled}>
                    {(
                      draggableProvided,
                      draggableSnapshot
                    ) => (
                      <Box
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={draggableProvided.draggableProps.style}
                        py={4}
                      >
                      {item.id === activeAnswerId
                        ? (
                          <AnswerEditor
                            answer={item}
                            position={isInterviewOrFillInBlank ? getInterviewOrFillInBlankAnswerPosition(item.position) : item.position + 1}
                            hasDependentLogic={hasDependentLogic}
                            questionType={questionType}
                            dragging={draggableSnapshot.isDragging}
                            dispatch={dispatch}
                            handleProps={draggableProvided.dragHandleProps}
                          />
                          )
                        : (
                          <AnswersListItem
                            answer={item}
                            position={isInterviewOrFillInBlank ? getInterviewOrFillInBlankAnswerPosition(item.position) : item.position + 1}
                            questionType={questionType}
                            dragging={draggableSnapshot.isDragging}
                            dispatch={dispatch}
                            handleProps={draggableProvided.dragHandleProps}
                            activeAnswerId={activeAnswerId}
                          />
                          )
                      }
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </>
        </div>
      )}
    </Droppable>
    </ScrollArea.Autosize>
      {!canAddAnswers
        ? null
        : (
          <>
            <Grid my='xs'>
              <Grid.Col span={8}>
                <Group>
                  <AddNewAnswer
                    questionId={questionId}
                    dispatch={dispatch}
                  />
                  {hasAnswerTemplates(questionType) && (
                      <AnswerTemplates
                        questionId={questionId}
                        dispatch={dispatch}
                        answersList={answersList}
                      />
                  )}
                </Group>
              </Grid.Col>
              {canRemoveAnswers
                ? (
                <Grid.Col span={4}>
                  <Flex justify='flex-end'>
                  { answers.has(activeAnswerId)
                    ? (
                    <Button color='red' onClick={() => {
                      dispatch({ questionId: questionId, answerId: activeAnswerId, type: QuestionStateUpdate.RemoveAnswer })
                      if (hasDependentLogic) {
                        reduxDispatch(associatedAnswersChanged({ questionId }))
                      }
                    }}>Delete selected</Button>
                      )
                    : <Button color='red' disabled={answersList.length === 0} variant='light' onClick={() => dispatch({ questionId: questionId, type: QuestionStateUpdate.ReplaceAnswers, payload: [] })}>Clear Answers</Button> }
                    </Flex>
                </Grid.Col>
                  )
                : null }
            </Grid>
            <Space h="md" />
          </>
          )
      }
    </Box>
  )
})

export default AnswersList

/**
 * @param {Answer} answer
 * @param {QuestionType} questionType
 * @param {boolean} dragging
 * @param dispatch
 */
const AnswersListItem = memo(function AnswersListItem ({ answer, position, questionType, dragging, dispatch, handleProps, activeAnswerId }) {
  const { hovered, ref } = useHover();

  const focusAnswer = useCallback(() => {
    dispatch({ type: QuestionStateUpdate.FocusAnswer, answerId: answer.id })
  }, [answer.id, dispatch])

  const isChooseAll = questionType === QuestionType.ChooseAllThatApply
  const isInterview = questionType === QuestionType.Interview
  const isFillInTheBlank = questionType === QuestionType.FillInTheBlank
  const isPresetRatingQuestion = isPresetRating(questionType)

  return (
    <Paper
      bg={(dragging ? 'blue.1' : (hovered ? 'gray.2' : 'gray.1'))}
      onClick={focusAnswer}
      ref={ref}
    >
      <Grid justify='flex-start' align='center'>
        {isFillInTheBlank
          ? null
          : (
            <Grid.Col span={2}>
              <Flex align='flex-end'>
                {!!handleProps &&
                  <ActionIcon {...handleProps}>
                    <IconGripVertical />
                  </ActionIcon>
                }
                <Text ta='center'>{(position).toString() + '.'}</Text>
              </Flex>
            </Grid.Col>
            )}
        <Grid.Col span='auto'>
          <UnstyledButton w='100%' ta='left' truncate='end' c={answer.content === 'New Answer' ? 'dimmed' : 'inherit'}>{answer.content}</UnstyledButton>
        </Grid.Col>
        {isInterview
          ? null
          : (
            <Grid.Col span={2}>
              <Center>
                <UnstyledButton p='xs'>{answerScoreToString(answer.score)}</UnstyledButton>
              </Center>
            </Grid.Col>
            )}
        {(isInterview || isChooseAll || isPresetRatingQuestion)
          ? null
          : (
            <Grid.Col span={2}>
              <Center>
                <Checkbox
                  disabled={isFillInTheBlank}
                  checked={!!answer.correct}
                  onChange={(event) => dispatch({ type: QuestionStateUpdate.SetCorrectAnswer, answerId: answer.id, questionId: answer.questionId, newScore: (event.currentTarget.checked ? 1 : 0) })}
                />
              </Center>
            </Grid.Col>
            )}
      </Grid>
    </Paper>
  )
})

/**
 * @param {int} questionId
 * @param dispatch
 */
const AddNewAnswer = memo(function AddNewAnswer ({ questionId, dispatch }) {
  return (
    <div>
      <Button color='success' variant='light' onClick={() => dispatch({ questionId: questionId, type: QuestionStateUpdate.NewAnswer })}>Add answer</Button>
    </div>
  )
})
