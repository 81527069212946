import { apiSlice, ApiTags } from '../../../react/api'

export const accountsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLoggedInAccount: builder.query({
      query: () => 'accounts/my-account',
      providesTags: (result, error, id) => [{ type: ApiTags.Accounts, id: result.id }, { type: ApiTags.Accounts, id: 'my-account' }]
    }),
    getMyAccountList: builder.query({
      query: () => 'accounts/my-account-list',
      providesTags: (result) => [{ type: ApiTags.Accounts, id: result.id }, { type: ApiTags.Accounts, id: 'my-account' }]
    })
  })
})

export const { useGetLoggedInAccountQuery, useGetMyAccountListQuery } = accountsApi
