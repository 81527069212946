import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useGetMyAccountListQuery } from '../../redux/query/account/accountsApi.slice';

export function useFirstRender () {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export function useAccount () {
  const { data: account } = useGetMyAccountListQuery()

  const access = useMemo(() => ({ access: getAccess(account?.roles ?? []) }), [account])

  return useMemo(() => account ? { ...account, ...access } : { ...access }, [account, access])
}

function getAccess (roles) {
  roles = roles.map(r => r.name)

  const DEVELOPER = _.includes(roles, 'ROLE_DEVELOPER')

  const ADMIN = _.includes(roles, 'ROLE_ADMIN') || DEVELOPER

  const BETA_TESTER = _.includes(roles, 'ROLE_BETA_TESTER')

  const PORTAL_CLIENT = _.includes(roles, 'ROLE_PORTAL_CLIENT')

  const SB_FACILITATOR = _.includes(roles, 'ROLE_SB_FACILITATOR')

  const SB_LOCATION_ADMIN = _.includes(roles, 'ROLE_SB_LOCATION_ADMIN')

  const SB_SUPER_ADMIN = _.includes(roles, 'ROLE_SB_SUPER_ADMIN')

  const DEMO = _.includes(roles, 'ROLE_DISC_DEMO')

  return {
    DEVELOPER,
    ADMIN,
    DEMO,
    BETA_TESTER,
    PORTAL_CLIENT,
    SB_FACILITATOR,
    SB_LOCATION_ADMIN,
    SB_SUPER_ADMIN
  }
}
